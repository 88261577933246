<template>
  <div>
    <app-card>
      <template #body>
        <team-form :team="team" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'teamsList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-team-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-team-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_team') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import TeamForm from './components/TeamForm';

export default {
  components: {
    AppCard,
    AppModal,
    TeamForm
  },
  data: () => ({
    form: {}
  }),
  beforeMount() {
    this.$store.dispatch('teamsStore/GET_TEAM', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      team: 'teamsStore/TEAM'
    })
  },
  methods: {
    updateItem() {
      this.$store.dispatch('teamsStore/UPDATE', {id: this.team.id, data: this.form}).then(response => {
        this.$router.push({name: 'teamsList'});
      });
    },
    deleteItem() {
      this.$store.dispatch('teamsStore/DELETE', this.team.id).then(() => {
        this.$router.push({name: 'teamsList'});
      });
    }
  }
}
</script>